import axios from "axios";
import qs from "qs";

const ax = axios.create({
  baseURL: "https://www.dyz.zjfilm.cn/zjfilm",
  transformRequest: [
    (data) => qs.stringify(data), // 序列化请求的数据
  ],
  // 修改请求的数据再发送到服务器
  // transformRequest: [
  //   data => JSON.stringify(data) // 序列化请求的数据
  // ],
  // 修改请求头信息
  headers: {
    contentType: "application/x-www-form-urlencoded; charset=utf-8",
  },
  timeout: 10000,
});

// 请求拦截
// ax.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (err) => {
//     console.log(err);
//   }
// );
// 添加 axios 实例响应拦截器
ax.interceptors.response.use(
  (res) => {
    if (res.data.data) return res.data.data;
    else {
      return res.data;
    }
  },
  (err) => {
    console.log("%c axios response error", "color:#f05b72;font-size:15px", err);
  }
);

export default function (url, method, params = {}) {
  if (JSON.stringify(params) != "{}") {
    console.log(
      "%c > 请求 API:" + url + "%c  Data:",
      "font-size:15px;color:#bed742;",
      "font-size:15px;color:#f58220;",
      params
    );
  } else {
    console.log("%c > 请求 API:" + url, "font-size:15px;color:#bed742;");
  }
  // 首先判断是get请求还是post请求
  let data = method.toLocaleLowerCase() === "get" ? "params" : "data";
  return ax({
    method,
    url,
    [data]: params, // 差异点在于data的值
  }).then(
    (res) => {
      // console.log("resolve", res);
      return Promise.resolve(res);
    },
    (err) => {
      // console.log(111111111);
      return Promise.reject(err);
    }
  );
}
