import ax from "./axios";

export const getList = (params) => ax("/News/GetList", "post", params);
export const getNews = (params) => ax("/News/Get", "post", params);
export const getFilmList = (params) => ax("/Film/GetList", "post", params);
export const getFilm = (params) => ax("/Film/Get", "post", params);

export const getSignature = (params) => ax("/wx/signature", "get", params);
// 上传
export const postSubmit = (params) =>
  ax("/PostData/PostCommon", "post", params);

// 获取访问IP记录
export const getIP = (params) => ax("/H5/Log", "get", params);

export const getCloseTime = (params) =>
  ax("/ProjectSubmission2024/Closed", "post", params);
