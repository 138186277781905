<template>
  <div id="header">
    <div
      class="header-container"
      :style="'background-color:' + backgroundColor"
    >
      <router-link :to="{ name: 'Home' }">
        <div class="header-logo">
          <img src="@/assets/images/logo.png" />
        </div>
        <div class="header-logo-txt">
          <div class="header-logo-txt-txt1">2024青年电影周（浙江）</div>
          <div class="header-logo-txt-txt2">
            2024 ZHEJIANG YOUTH FILM FESTIVAL
          </div>
        </div>
      </router-link>

      <!-- <div class="header-query">
        <img
          src="@/assets/images/query-white.png"
          @click="searchshow = !searchshow"
        />
      </div>
      <div class="header-query-txt" @click="searchshow = !searchshow">搜索</div> -->

      <div class="header-menu">
        <img
          src="@/assets/images/menu-white.png"
          @click="menuShow = !menuShow"
        />
      </div>
    </div>
    <el-collapse-transition>
      <div id="menu" class="header-menu-container" v-show="menuShow">
        <el-menu
          class="header-menu-list"
          text-color="#ffffff"
          :unique-opened="true"
        >
          <el-menu-item index="1">
            <div class="item-title" @click="gotoSubmission">创投</div>
          </el-menu-item>
          <!-- <el-menu-item index="2">
            <div class="item-title" @click="gotoGallery('/zjr/index')">
              电影·浙江日
            </div>
          </el-menu-item> -->
          <el-submenu index="3">
            <template slot="title">
              <div class="item-title">展映</div>
            </template>
            <el-menu-item-group>
              <el-menu-item index="3-1">
                <div @click="gotoShow('/zy/wangjie')">往届电影展映</div>
              </el-menu-item>
              <el-menu-item index="3-2">
                <div @click="gotoShow('/zy/benjie')">中外影片展映</div>
              </el-menu-item>
              <!-- <el-menu-item index="3-1">
                <div @click="gotoShow('/zy/show')">多国别电影展映</div>
              </el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="4">
            <div class="item-title">对话</div>
          </el-menu-item>
          <el-submenu index="5">
            <template slot="title">
              <div class="item-title">活动</div>
            </template>
            <el-menu-item-group>
              <el-menu-item index="5-1">
                <div @click="gotoGallery('/hd/px')">十大浙产影片评选</div>
              </el-menu-item>
              <el-menu-item index="5-10">
                <div @click="gotoGallery('/hd/index')">大学生电影周</div>
              </el-menu-item>
              <el-menu-item index="5-20">
                <div @click="gotoGallery('/hd/hgs')">电影好故事</div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-menu-item index="6">
            <a
              href="https://www.qndyz.zjfilm.cn/#/news?id=67"
              target="_blank"
              style="color: white; font-size: 14px"
            >
              2023青年电影周（浙江）主视觉海报及logo下载
            </a>
          </el-menu-item> -->
        </el-menu>
      </div>
    </el-collapse-transition>

    <transition name="el-zoom-in-top">
      <div id="search" class="header-query-container" v-show="searchshow">
        <input id="queryId" type="text" class="query-input" value="" />
        <div class="query-input-btn">
          <img src="@/assets/images/query.png" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "Header",
    data() {
      return {
        menuShow: false,
        searchshow: false,
        showerDropDown: false,
        acDropDown: false,
      };
    },
    props: {
      backgroundColor: {
        type: String,
        // default设置默认值
        // default: "rgba(0, 0, 0, 0.5)",
        default: "rgba(0, 0, 0, 0.2)",
      },
    },
    methods: {
      changeDropDown(e) {
        let type = e.currentTarget.id;
        if (type == "showHeader") {
          this.showerDropDown = !this.showerDropDown;
        }
        if (type == "acHeader") {
          this.acDropDown = !this.acDropDown;
        }
      },
      // 跳转
      gotoSubmission() {
        this.$router.push({
          path: "/ct",
        });
      },
      gotoShow(path) {
        this.$router.push({
          path,
        });
      },
      gotoGallery(path) {
        this.$router.push({
          path,
        });
      },
    },
  };
</script>

<style scoped>
  .header-container {
    position: fixed;
    width: 100%;
    height: 50px;
    text-align: right;
    box-sizing: border-box;
    padding: 5px 14px;
    z-index: 9999;
  }

  /* 菜单栏 */
  .header-logo {
    position: absolute;
    left: 14px;
    top: 8px;
    height: 34px;
    width: 34px;
  }
  .header-logo-txt {
    position: absolute;
    left: 54px;
    top: 12px;
    font-family: "zt";
    height: 26px;
    color: #fff;
    text-align: left;
  }
  .header-logo-txt-txt1 {
    position: relative;
    height: 13px;
    line-height: 13px;
    font-family: "zt";
    font-size: 13px;
  }
  .header-logo-txt-txt2 {
    position: relative;
    height: 12px;
    font-family: "zt";
    font-size: 12px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    left: -15%;
  }
  .header-menu {
    position: relative;
    height: 40px;
    width: 40px;
    display: inline-block;
    vertical-align: middle;
  }
  .header-query {
    position: relative;
    height: 18px;
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    margin: 1px 0;
  }
  .header-query-txt {
    position: relative;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    margin: 1px 4px;
  }
  .header-container img {
    height: 100%;
    width: 100%;
    display: block;
  }
  /* 菜单主体 */
  .header-menu-container {
    position: fixed;
    top: 50px;
    width: 100%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    z-index: 9999;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .header-menu-list {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .item-title {
    width: 100%;
    font-size: 20px;
    letter-spacing: 1px;
    font-family: "Microsoft YaHei", 微软雅黑;
  }
  /* 搜索框 */
  .header-query-container {
    position: fixed;
    top: 50px;
    width: 100%;
    height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 50px 0 14px;
    z-index: 9999;
    box-shadow: 0 0 4px 1px rgb(229, 229, 229);
  }
  .query-input {
    border: 0;
    outline: none;
    margin-top: 5px;
    height: 40px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .query-input-btn {
    position: absolute;
    top: 11px;
    right: 14px;
    height: 28px;
    width: 28px;
  }
  .query-input-btn img {
    height: 100%;
    width: 100%;
    display: block;
  }
</style>
<style>
  .el-menu {
    border: none;
    background-color: rgba(0, 0, 0, 0);
  }
  .el-menu-item {
    font-size: 16px;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  .el-menu-item.is-active {
    color: #fffff0;
    font-weight: bold;
  }
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  .el-submenu__title i {
    color: #fff;
  }
</style>
